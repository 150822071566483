<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="milestonesFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">MILESTONES</h1>
                        <v-btn
                            rounded
                            color="primary"
                            @click="openCreateMilestone"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.name`]="{ header }">
                <v-text-field
                    class="pb-2 pt-3 mt-2"
                    :label="header.text"
                    v-model="searchByName"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:body="props">
                <draggable
                    :list="milestonesFiltered"
                    tag="tbody"
                    @change="updateMilestonesOrder"
                    handle=".handle"
                    :disabled="searchByName && searchByName != ''"
                >
                    <tr
                        v-for="(item, index) in props.items"
                        :key="index"
                        @mouseover="handle = item.id"
                        @mouseleave="handle = undefined"
                    >
                        <td class="handle">
                            <div class="d-flex justify-center">
                                <v-btn
                                    small
                                    icon
                                    :class="{ invisible: item.id != handle }"
                                    style="cursor: grab !important"
                                >
                                    <v-icon>mdi-pan-vertical</v-icon>
                                </v-btn>
                            </div>
                        </td>
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>
                            <div class="d-flex justify-center">
                                <v-btn
                                    small
                                    icon
                                    @click="openEditMilestone(item)"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    icon
                                    @click="openDeleteMilestone(item)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </draggable>
            </template>
        </v-data-table>
        <!--MILESTONE FORM-->
        <v-dialog
            v-model="openMilestoneForm"
            :retain-focus="false"
            persistent
            width="600px"
        >
            <MilestonesForm
                v-if="openMilestoneForm"
                :createForm="createForm"
                :milestone="selectedMilestone"
                :originalMilestone="originalMilestone"
                :settingId="setting.id"
                @addMilestone="addMilestone"
                @replaceMilestone="replaceMilestone"
                @close="closeEditMilestone"
            />
        </v-dialog>
        <!--Delete Milestone-->
        <v-dialog
            v-model="openDeleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openDeleteWarning">
                <v-card-title class="text-h5">Delete Milestone</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this milestone?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteMilestone">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteMilestone"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
import Draggable from 'vuedraggable'

export default {
    name: 'Milestones',
    props: {
        setting: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        MilestonesForm: () =>
            import('@/components/Milestones/MilestonesForm.vue'),
        Draggable,
    },
    data: () => ({
        loading: false,
        milestones: [],
        selectedMilestone: {},
        originalMilestone: {},
        createForm: true,
        openMilestoneForm: false,
        openDeleteWarning: false,
        settings: {},
        headers: [
            {
                text: '',
                value: 'handler',
                sortable: false,
            },
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
            },
            {
                text: 'NAME',
                value: 'name',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        searchByName: undefined,
        handle: undefined,
    }),
    async mounted() {
        try {
            this.loading = true
            this.milestones = await API.getMilestones(this.setting.id)
        } catch (error) {
            this.setErrorItems({
                name: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        milestonesFiltered() {
            const conditions = []
            if (this.searchByName) {
                conditions.push(this.filterByName)
            }
            if (conditions.length > 0) {
                return this.milestones.filter(milestone => {
                    return conditions.every(condition => {
                        return condition(milestone)
                    })
                })
            }
            return this.milestones
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterByName(item) {
            return item.name
                .toLowerCase()
                .includes(this.searchByName.toLowerCase())
        },
        replaceMilestone(milestoneToUpdate) {
            const index = this.milestones.findIndex(
                milestone => milestone.id == milestoneToUpdate.id
            )
            if (index >= 0) {
                this.milestones.splice(index, 1, {
                    ...this.milestones[index],
                    ...milestoneToUpdate,
                })
            }
        },
        addMilestone(milestone) {
            if (this.milestones && this.milestones.length > 0) {
                this.milestones.push(milestone)
            } else {
                this.milestones = [milestone]
            }
        },
        removeMilestone() {
            const index = this.milestones.findIndex(
                milestone => milestone.id == this.selectedMilestone.id
            )
            if (index >= 0) {
                this.milestones.splice(index, 1)
            }
        },
        async deleteMilestone() {
            try {
                this.loading = true
                await API.deleteMilestone({
                    settingId: this.setting.id,
                    milestoneId: this.selectedMilestone.id,
                })
                this.removeMilestone()
                this.closeDeleteMilestone()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteMilestone() {
            this.selectedMilestone = {}
            this.openDeleteWarning = false
        },
        openDeleteMilestone(item) {
            this.selectedMilestone = _.cloneDeep(item)
            this.openDeleteWarning = true
        },
        closeEditMilestone() {
            this.createForm = true
            this.selectedMilestone = {}
            this.originalMilestone = {}
            this.openMilestoneForm = false
        },
        openEditMilestone(item) {
            this.createForm = false
            this.selectedMilestone = _.cloneDeep(item)
            this.originalMilestone = _.cloneDeep(item)
            this.openMilestoneForm = true
        },
        openCreateMilestone() {
            this.createForm = true
            this.openMilestoneForm = true
        },
        async updateMilestonesOrder(evt) {
            if (evt.moved) {
                await API.updateMilestonesOrder(
                    this.setting.id,
                    this.milestones
                )
            }
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
</style>
